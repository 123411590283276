$use_maison: true;
/* stylelint-disable selector-pseudo-class-no-unknown */

@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';

.builder-homepage__wrapper {
  line-height: 0;
  margin-top: -21px;
  max-width: 100vw;
  min-height: 200vh;
  overflow: hidden;

  @include breakpoint(tablet) {
    text-align: center;
  }

  :global {
    a {
      text-decoration: none;
    }

    img:not([src]) {
      visibility: hidden;
    }

    img[src='jpg'] {
      background: #eee;
    }
  }
}
